import _mixinBase from "./mixin-base";
import _preprocessorMixin from "../position-tracking/preprocessor-mixin";
import _mixin from "../../utils/mixin";
var exports = {};
const ErrorReportingMixinBase = _mixinBase;
const PositionTrackingPreprocessorMixin = _preprocessorMixin;
const Mixin = _mixin;

class ErrorReportingPreprocessorMixin extends ErrorReportingMixinBase {
  constructor(preprocessor, opts) {
    super(preprocessor, opts);
    this.posTracker = Mixin.install(preprocessor, PositionTrackingPreprocessorMixin);
    this.lastErrOffset = -1;
  }

  _reportError(code) {
    //NOTE: avoid reporting error twice on advance/retreat
    if (this.lastErrOffset !== this.posTracker.offset) {
      this.lastErrOffset = this.posTracker.offset;

      super._reportError(code);
    }
  }

}

exports = ErrorReportingPreprocessorMixin;
export default exports;