import _mixinBase from "./mixin-base";
import _tokenizerMixin from "./tokenizer-mixin";
import _tokenizerMixin2 from "../location-info/tokenizer-mixin";
import _mixin from "../../utils/mixin";
var exports = {};
const ErrorReportingMixinBase = _mixinBase;
const ErrorReportingTokenizerMixin = _tokenizerMixin;
const LocationInfoTokenizerMixin = _tokenizerMixin2;
const Mixin = _mixin;

class ErrorReportingParserMixin extends ErrorReportingMixinBase {
  constructor(parser, opts) {
    super(parser, opts);
    this.opts = opts;
    this.ctLoc = null;
    this.locBeforeToken = false;
  }

  _setErrorLocation(err) {
    if (this.ctLoc) {
      err.startLine = this.ctLoc.startLine;
      err.startCol = this.ctLoc.startCol;
      err.startOffset = this.ctLoc.startOffset;
      err.endLine = this.locBeforeToken ? this.ctLoc.startLine : this.ctLoc.endLine;
      err.endCol = this.locBeforeToken ? this.ctLoc.startCol : this.ctLoc.endCol;
      err.endOffset = this.locBeforeToken ? this.ctLoc.startOffset : this.ctLoc.endOffset;
    }
  }

  _getOverriddenMethods(mxn, orig) {
    return {
      _bootstrap(document, fragmentContext) {
        orig._bootstrap.call(this, document, fragmentContext);

        Mixin.install(this.tokenizer, ErrorReportingTokenizerMixin, mxn.opts);
        Mixin.install(this.tokenizer, LocationInfoTokenizerMixin);
      },

      _processInputToken(token) {
        mxn.ctLoc = token.location;

        orig._processInputToken.call(this, token);
      },

      _err(code, options) {
        mxn.locBeforeToken = options && options.beforeToken;

        mxn._reportError(code);
      }

    };
  }

}

exports = ErrorReportingParserMixin;
export default exports;