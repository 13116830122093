import _mixinBase from "./mixin-base";
import _preprocessorMixin from "./preprocessor-mixin";
import _mixin from "../../utils/mixin";
var exports = {};
const ErrorReportingMixinBase = _mixinBase;
const ErrorReportingPreprocessorMixin = _preprocessorMixin;
const Mixin = _mixin;

class ErrorReportingTokenizerMixin extends ErrorReportingMixinBase {
  constructor(tokenizer, opts) {
    super(tokenizer, opts);
    const preprocessorMixin = Mixin.install(tokenizer.preprocessor, ErrorReportingPreprocessorMixin, opts);
    this.posTracker = preprocessorMixin.posTracker;
  }

}

exports = ErrorReportingTokenizerMixin;
export default exports;