import _mixin from "../../utils/mixin";
var exports = {};
const Mixin = _mixin;

class LocationInfoOpenElementStackMixin extends Mixin {
  constructor(stack, opts) {
    super(stack);
    this.onItemPop = opts.onItemPop;
  }

  _getOverriddenMethods(mxn, orig) {
    return {
      pop() {
        mxn.onItemPop(this.current);
        orig.pop.call(this);
      },

      popAllUpToHtmlElement() {
        for (let i = this.stackTop; i > 0; i--) {
          mxn.onItemPop(this.items[i]);
        }

        orig.popAllUpToHtmlElement.call(this);
      },

      remove(element) {
        mxn.onItemPop(this.current);
        orig.remove.call(this, element);
      }

    };
  }

}

exports = LocationInfoOpenElementStackMixin;
export default exports;