import _mixin from "../../utils/mixin";
var exports = {};
const Mixin = _mixin;

class ErrorReportingMixinBase extends Mixin {
  constructor(host, opts) {
    super(host);
    this.posTracker = null;
    this.onParseError = opts.onParseError;
  }

  _setErrorLocation(err) {
    err.startLine = err.endLine = this.posTracker.line;
    err.startCol = err.endCol = this.posTracker.col;
    err.startOffset = err.endOffset = this.posTracker.offset;
  }

  _reportError(code) {
    const err = {
      code: code,
      startLine: -1,
      startCol: -1,
      startOffset: -1,
      endLine: -1,
      endCol: -1,
      endOffset: -1
    };

    this._setErrorLocation(err);

    this.onParseError(err);
  }

  _getOverriddenMethods(mxn) {
    return {
      _err(code) {
        mxn._reportError(code);
      }

    };
  }

}

exports = ErrorReportingMixinBase;
export default exports;